import React from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <Logo>Resolution</Logo>
          <Nav>
            <Navigations>Home</Navigations>
            <Navigations>About</Navigations>
            <Navigations>Services</Navigations>
            <Navigations>Features</Navigations>
            <Navigations>Blog</Navigations>
            <Navigations>Contact Us</Navigations>
            <Button> Get A Quote</Button>
          </Nav>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Header;

const Button = styled.div`
  width: 15;
  background: rgb(255, 6, 36);
  background: linear-gradient(
    90deg,
    rgba(255, 6, 36, 1) 0%,
    rgba(249, 6, 56, 1) 46%,
    rgba(255, 85, 0, 1) 80%
  );
  outline: none;
  border: 0px;
  border-radius: 0px 20px 0px 20px;
  color: white;
  padding: 15px 30px;
  font-weight: 00;
  cursor: pointer;
`;

const Navigations = styled.div`
  cursor: pointer;
`;

const Nav = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  font-size: 30px;
  font-weight: bolder;
  color: white;
`;

const Wrapper = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 700;
`;

const Container = styled.div`
  background-color: #442475;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
