import React from "react";
import styled from "styled-components";
import Hero from "./Hero";
import Agency from "./Agency";
import Specialization from "./Specialization";
import OurFeatures from "./OurFeatures";
import OurBlogNews from "./OurBlogNews";

const HomeScreen = () => {
  return (
    <div>
      <Container>
        <Hero />
        <Agency />
        <Specialization />
        <OurFeatures />
        <OurBlogNews />
      </Container>
    </div>
  );
};

export default HomeScreen;

const Container = styled.div`
  background-color: whitesmoke;
`;
