import React from "react";
import styled from "styled-components";

const OurFeatures = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <Header>
            <h1>Our Features</h1>
            <Line></Line>
          </Header>
          <BoxCon>
            <Box1>
              <h2>Idea & Analsis</h2>
              <p>
                Praesent tincidunt congue est ut hendrerit. Pellentesque et eros
                sit amet ipsum venenatis.
              </p>
              <h2>Designing</h2>
              <p>
                Praesent tincidunt congue est ut hendrerit. Pellentesque et eros
                sit amet ipsum venenatis.
              </p>
            </Box1>
            <Box2>
              <Image />
            </Box2>
            <Box3>
              <h2>Development</h2>
              <p>
                Praesent tincidunt congue est ut hendrerit. Pellentesque et eros
                sit amet ipsum venenatis.
              </p>
              <h2>Testing and Launchuing</h2>
              <p>
                Praesent tincidunt congue est ut hendrerit. Pellentesque et eros
                sit amet ipsum venenatis.
              </p>
            </Box3>
          </BoxCon>
        </Wrapper>
      </Container>
    </div>
  );
};

export default OurFeatures;

const Image = styled.img`
  height: 300px;
  width: 300px;
  background-color: green;
`;

const Line = styled.div`
  margin-bottom: 50px;
  outline: none;
  height: 7px;
  width: 15%;
  background: linear-gradient(to right, #ff0015, orangered, orange);
  border-radius: 10px;
`;

const Box3 = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box2 = styled.div``;

const Box1 = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxCon = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  h2 {
    font-weight: bold;
    color: navy;
  }

  p {
    margin-top: 0px;
    padding-top: 0px;
    width: 65%;
    color: blue;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 50px;
    margin-bottom: 10px;
  }
`;

const Wrapper = styled.div`
  width: 90%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;
