import React from "react";
import styled from "styled-components";
import img1 from "../Assets/hero-banner.png";
const Hero = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
          </svg>
        </Wrapper>
        <Content>
          <Fcol>
            <Row1>We are Web Developers from Nigeria</Row1>
            <Row2>We build and Deploy websites that Users Love</Row2>
            <Row3>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Assumenda, laboriosam. Ad quas odio quibusdam iure reiciendis
              sapiente, rerum ducimus perspiciatis.
            </Row3>
            <Button>Learn More</Button>
          </Fcol>
          <Scol>
            <Image src={img1} />
          </Scol>
        </Content>
      </Container>
    </div>
  );
};

export default Hero;

const Button = styled.div`
  width: 15%;
  background: rgb(255, 6, 36);
  background: linear-gradient(
    90deg,
    rgba(255, 6, 36, 1) 0%,
    rgba(249, 6, 56, 1) 46%,
    rgba(255, 85, 0, 1) 80%
  );
  outline: none;
  border: 0px;
  border-radius: 0px 20px 0px 20px;
  color: white;
  padding: 15px 30px;
  font-weight: 00;
`;

const Row3 = styled.div`
  font-size: medium;
  padding-bottom: 20px;
  width: 80%;
`;

const Row2 = styled.div`
  font-size: 50px;
  padding-bottom: 20px;
  font-weight: bold;
  width: 90%;
`;

const Row1 = styled.div`
  font-size: 20px;
  padding-bottom: 20px;
  color: goldenrod;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const Scol = styled.div`
  width: 100%;
`;

const Fcol = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 90%;
  position: absolute;
  top: 10%;
  left: 5%;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  svg {
    position: relative;
    display: block;
    width: calc(300% + 1.3px);
    height: 600px;
    transform: rotateY(180deg);
  }

  path {
    fill: #442475;
  }
`;

const Container = styled.div`
  height: 600px;
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
`;
