import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <div>
      <Container>
        <MainCon>
          <Fcol>
            <ul>
              <li>
                <h1>Resolution</h1>
              </li>
              <li>
                <p>
                  Maecenas pellentesque placerat quam, in finibus nisl tincidunt
                  sed. Aliquam magna augue, malesuada ut feugiat eget, cursus
                  eget felis.
                </p>
              </li>
            </ul>
            <Icons>
              <ul>
                <li>fb</li>
                <li>ing</li>
                <li>tw</li>
              </ul>
            </Icons>
          </Fcol>
          <Scol>
            <First>
              <h2>Our Link</h2>
              <ul>
                <li>Home</li>
                <li>About Us</li>
                <li>Services</li>
                <li>Team</li>
                <li>Blog</li>
              </ul>
            </First>
            <Second>
              {" "}
              <h2>Our Services</h2>
              <ul>
                <li>Strategy & Research</li>
                <li>Web Development</li>
                <li>Web Solution</li>
                <li>Digital Marketing</li>
                <li>App Design</li>
              </ul>
            </Second>
            <Third>
              {" "}
              <h2>Other Links</h2>
              <ul>
                <li>FAQ</li>
                <li>Portfolio</li>
                <li>Privacy Policy</li>
                <li>
                  Terms & <br /> Condition
                </li>
                <li>Support</li>
              </ul>
            </Third>
            <Fourth>
              {" "}
              <h2>Contact Us</h2>
              <ul>
                <li>
                  +234-819-835-7462 <br /> +234-891-123-0000
                </li>
                <li>
                  info@desinic.com <br /> desinic.com
                </li>
                <li>
                  Pontiac, Michigan, United <br /> States of America
                </li>
              </ul>
            </Fourth>
          </Scol>
        </MainCon>
        <CopyCon> © 2022 TechSis. All Right Reserved</CopyCon>
      </Container>
    </div>
  );
};

export default Footer;

const Icons = styled.div`
  ul {
    display: flex;
    width: 20%;
  }
  li {
    margin-right: 10px;
  }
`;

const First = styled.div``;

const Second = styled.div``;

const Third = styled.div``;

const Fourth = styled.div``;

const Scol = styled.div`
  flex: 1.8;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  ul {
    list-style: none;
    text-align: left;
    margin-left: 0px;
    padding-left: 0px;
  }
  li {
    padding: 10px 0px;
    font-weight: 400;
  }
`;

const Fcol = styled.div`
  ul {
    list-style: none;
  }

  p {
    width: 80%;
    font-weight: 400;
    line-height: 25px;
  }

  flex: 1;
`;

const CopyCon = styled.div`
  height: 10vh;
  width: 100%;
  background-color: indigo;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
`;

const MainCon = styled.div`
  height: 60vh;
  width: 90%;
  background-color: purple;
  color: white;
  display: flex;
`;

const Container = styled.div`
  background-color: purple;
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;
