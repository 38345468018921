import React from "react";
import styled from "styled-components";

const Specialization = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <Header>
            <Htext> Our Specialization</Htext>
            <Line></Line>
          </Header>
          <GridCard>
            <Card1>
              <Image />
              <h2>Strategy & Research</h2>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse
                consectetur voluptatem, molestias consequuntur excepturi
                architecto facilis reiciendis veniam animi minus perferendis
                praesentium laborum placeat sed?
              </p>
              <CircleIcon>&gt;</CircleIcon>
            </Card1>
            <Card2>
              {" "}
              <Image />
              <h2>Web Development</h2>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse
                consectetur voluptatem, molestias consequuntur excepturi
                architecto facilis reiciendis veniam animi minus perferendis
                praesentium laborum placeat sed?
              </p>
              <CircleIcon>&gt;</CircleIcon>
            </Card2>
            <Card3>
              <Image />
              <h2>Web Solution</h2>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse
                consectetur voluptatem, molestias consequuntur excepturi
                architecto facilis reiciendis veniam animi minus perferendis
                praesentium laborum placeat sed?
              </p>
              <CircleIcon>&gt;</CircleIcon>
            </Card3>
          </GridCard>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Specialization;

const CircleIcon = styled.div`
  background-color: white;
  border: 1px solid red;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
`;

const Image = styled.img`
  height: 300px;
  width: 300px;
  background-color: green;
`;

const Line = styled.div`
  margin-bottom: 50px;
  outline: none;
  height: 7px;
  width: 20%;
  background: linear-gradient(to right, #ff0015, orangered, orange);
  border-radius: 10px;
`;

const Htext = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Card3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  border: 1px solid silver;
  padding: 30px 15px;
  text-align: center;
`;

const Card2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  border: 1px solid silver;
  padding: 30px 15px;
  text-align: center;
`;

const Card1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 30px 15px;
  border: 1px solid silver;
  text-align: center;
`;

const GridCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 90%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;
