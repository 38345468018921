import React from "react";
import styled from "styled-components";

const Agency = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <GridCard1>
            <Fcol>
              <Image />
            </Fcol>
            <Scol>
              <Row1>Why Our Agency</Row1>
              <Line></Line>
              <Row2>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Debitis, provident beatae, repellat asperiores sequi error esse
                assumenda quibusdam cumque, alias non earum explicabo sit
                officiis dignissimos architecto accusantium natus dolore nobis
                blanditiis consequatur! Sunt, at!
              </Row2>
              <Row3>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Eligendi, quaerat. Quos reiciendis officiis nulla beatae illo
                deleniti molestias distinctio eius?
              </Row3>
              <GridCard2>
                <First></First>
                <Second></Second>
                <Third></Third>
              </GridCard2>
            </Scol>
          </GridCard1>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Agency;

const Image = styled.img`
  background-color: green;
  height: 450px;
  width: 500px;
`;

const Line = styled.div`
  margin-bottom: 50px;
  outline: none;
  height: 7px;
  width: 40%;
  background: linear-gradient(to right, #ff0015, orangered, orange);
  border-radius: 10px;
`;

const Row2 = styled.div`
  margin: 10px 0px;
  color: #5353fd;
`;

const Row3 = styled.div`
  margin: 20px 0px;
  color: #5555f4;
`;

const Row1 = styled.div`
  font-size: 40px;
  font-weight: bolder;
  color: navy;
  margin-bottom: 20px;
`;

const GridCard2 = styled.div``;

const GridCard1 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const Fcol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Scol = styled.div``;

const First = styled.div``;

const Second = styled.div``;

const Third = styled.div``;

const Wrapper = styled.div`
  width: 90%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px;
`;
