import React from "react";
import styled from "styled-components";

const OurBlogNews = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <Header>
            <h1>Our Features</h1>
            <Line></Line>
          </Header>
          <BoxCon>
            <Box1>
              <Image />
              <Description>
                <h2>
                  Vestibulum massa arcu, consectetu pellentesque scelerisque.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure
                  nihil ab dolorum alias molestiae eaque beatae molestias animi
                  accusamus incidunt.
                </p>
                <Icons>
                  <ul>
                    <li>Date</li>
                    <li>comment</li>
                    <li>share</li>
                  </ul>
                </Icons>
              </Description>
            </Box1>
            <Box2>
              <Image />
              <Description>
                <h2>
                  Vestibulum massa arcu, consectetu pellentesque scelerisque.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure
                  nihil ab dolorum alias molestiae eaque beatae molestias animi
                  accusamus incidunt.
                </p>
                <Icons>
                  <ul>
                    <li>Date</li>
                    <li>comment</li>
                    <li>share</li>
                  </ul>
                </Icons>
              </Description>
            </Box2>
            <Box1>
              <Image />
              <Description>
                <h2>
                  Vestibulum massa arcu, consectetu pellentesque scelerisque.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure
                  nihil ab dolorum alias molestiae eaque beatae molestias animi
                  accusamus incidunt.
                </p>
                <Icons>
                  <ul>
                    <li>Date</li>
                    <li>comment</li>
                    <li>share</li>
                  </ul>
                </Icons>
              </Description>
            </Box1>
            <Box3>
              <Image />
              <Description>
                <h2>
                  Vestibulum massa arcu, consectetu pellentesque scelerisque.
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure
                  nihil ab dolorum alias molestiae eaque beatae molestias animi
                  accusamus incidunt.
                </p>
                <Icons>
                  <ul>
                    <li>Date</li>
                    <li>comment</li>
                    <li>share</li>
                  </ul>
                </Icons>
              </Description>
            </Box3>
          </BoxCon>
        </Wrapper>
      </Container>
    </div>
  );
};

export default OurBlogNews;

const Icons = styled.div`
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0px;
  }

  li {
    margin-right: 10px;
  }
`;

const Description = styled.div`
  height: 100%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Image = styled.img`
  height: 300px;
  width: 400px;
  background-color: green;
  margin-left: 20px;
  margin-right: 50px;
`;

const Box1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid gray;
  padding: 20px 0px;
  margin: 20px 0px;
  border-radius: 10px;
  background-color: white;
`;
const Box2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid gray;
  padding: 20px 0px;
  border-radius: 10px;
  background-color: white;
`;
const Box3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid gray;
  padding: 20px 0px;
  border-radius: 10px;
  background-color: white;
`;

const BoxCon = styled.div``;

const Line = styled.div`
  margin-bottom: 50px;
  outline: none;
  height: 7px;
  width: 15%;
  background: linear-gradient(to right, #ff0015, orangered, orange);
  border-radius: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 50px;
    margin-bottom: 10px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  background-color: whitesmoke;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
`;
